import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { isTouchDevice } from 'utils/screen';
import { getTrackDetails } from 'utils/formatters';
import ASSET from 'constants/asset-types';
import ContentIcon from 'components/common/content-icon';
import { FilledBookmarkIcon, BookmarkIcon, InfoIcon } from 'components/icons';
import useStyles from './track-item.styles';


const TrackItem = memo((props) => {
  const { t } = useTranslation();
  const {
    trackId,
    trackName,
    image,
    creator,
    pages,
    duration,
    contentType,
    trackIndex,
    description,
    createdDate,
    onPlayClick,
    isInFavorites,
    addToFavorites,
    itemStyleProps,
    removeFromFavorites,
    onInfoIconClick,
    primaryAssetsTypes,
    withSeparator = true,
  } = props;
  const classes = useStyles(itemStyleProps);
  const isTouchableDevice = isTouchDevice();

  const trackDetails = useMemo(
    () =>
      getTrackDetails({ t, pages, duration, contentType, createdDate: itemStyleProps.isSmallView ? '' : createdDate }),
    [pages, duration, contentType, createdDate, itemStyleProps.isSmallView],
  );

  const isItemInFavorites = useMemo(() => isInFavorites(trackId), [trackId, isInFavorites]);

  const [isDisplayOverlay, setIsDisplayOverlay] = useState(false);

  const handleBookmarkClick = useCallback(() => {
    if (isItemInFavorites) {
      removeFromFavorites(trackId);
    } else {
      addToFavorites(trackId);
    }
  }, [trackId, isItemInFavorites]);

  const showOverlay = useCallback(() => {
    setIsDisplayOverlay(true);
  }, []);

  const hideOverlay = useCallback(() => {
    setIsDisplayOverlay(false);
  }, []);

  const stopPropagation = useCallback((event) => event.stopPropagation(), []);

  const handleImageClick = useCallback(() => {
    if (isTouchableDevice) {
      showOverlay();
    }
  }, [isTouchableDevice]);

  const eventHandlers = isTouchableDevice
    ? {
        onClick: handleImageClick,
      }
    : {
        onMouseEnter: showOverlay,
      };

  return (
    <>
    <Box onClick={stopPropagation} className={classes.trackItem}>
      <CardMedia image={image} onMouseLeave={hideOverlay} {...eventHandlers} className={classes.trackImage}>
        <Fade in={isDisplayOverlay} timeout={100}>
          <ContentIcon
            type="button"
            option="play"
            contentType={contentType}
            onClick={onPlayClick(trackIndex)}
            className={classes.playIconBtn}
          />
        </Fade>
      </CardMedia>
      <Box className={classes.trackDescriptionContainer}>
        <Box className={classes.trackCreatorContainer}>
          <Typography noWrap component="span" className={classes.trackCreatorText} title={creator}>
            {creator}
          </Typography>
        </Box>
        <Typography noWrap className={classes.trackName} title={trackName}>
          {trackName || '-'}
        </Typography>
        <Typography className={classes.trackDescription}>
          {description || '-'}
        </Typography>
        <Box className={classes.trackDetails}>
          {primaryAssetsTypes?.length === 1 ? (
            <>
              <ContentIcon contentType={contentType} iconClassName={classes.trackDetailsTimeIcon} />
              {trackDetails}
            </>
          ) : (
            primaryAssetsTypes.map((type) => (
              <ContentIcon key={type} contentType={type} iconClassName={classes.trackDetailsTimeIcon} />
            ))
          )}
          <Box className={classes.buttons}>
            <IconButton onClick={handleBookmarkClick} className={classes.trackBookmarkIconBtn}>
              {isItemInFavorites ? (
                <FilledBookmarkIcon className={classes.activeBookmarkIcon} />
              ) : (
                <BookmarkIcon className={classes.trackBookmarkIcon} />
              )}
            </IconButton>
            <IconButton onClick={onInfoIconClick(trackId)} className={classes.trackInfoIconBtn}>
              <InfoIcon className={classes.trackInfoIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  {withSeparator && <div className={classes.separator} />}
    </>
  );
});

TrackItem.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  trackId: PropTypes.number,
  trackName: PropTypes.string,
  image: PropTypes.string,
  creator: PropTypes.string,
  creatorImage: PropTypes.string,
  pages: PropTypes.number,
  duration: PropTypes.number,
  contentType: PropTypes.oneOf(Object.values(ASSET.contentType)),
  description: PropTypes.string,
  createdDate: PropTypes.string,
  onPlayClick: PropTypes.func,
  isInFavorites: PropTypes.func.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func.isRequired,
  onInfoIconClick: PropTypes.func,
  itemStyleProps: PropTypes.shape({
    width: PropTypes.number,
    itemHeight: PropTypes.number,
    isSmallView: PropTypes.bool,
    maxItemWidth: PropTypes.number,
    calculatedHeight: PropTypes.number,
    itemHeightWithPadding: PropTypes.number,
  }),
  primaryAssetsTypes: PropTypes.arrayOf(PropTypes.string),
  withSeparator: PropTypes.bool,
};

export default TrackItem;
