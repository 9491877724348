import makeStyles from '@material-ui/core/styles/makeStyles';
import { lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  trackItem: {
    display: 'flex',
    width: '100%',
    maxWidth: ({ maxItemWidth }) => maxItemWidth,
    height: ({ itemHeight }) => itemHeight,
    position: 'relative',
    marginBottom: 18,
    margin: '0 auto',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      alignItems: 'center',
      height: ({ isSmallView, itemHeight, itemHeightWithPadding }) =>
        isSmallView ? itemHeightWithPadding : itemHeight,
    },
  },
  trackImage: {
    minWidth: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight + 6 : itemHeight),
    maxWidth: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight + 6 : itemHeight),
    width: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight + 6 : itemHeight),
    height: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight + 6 : itemHeight),
    position: 'relative',
    borderRadius: theme.palette.colors.cornerRadius,
    border: `0.5px solid ${theme.palette.colors.borderColor}`,
    marginLeft: ({ isSmallView }) => (isSmallView ? 8 : 0),
  },
  trackDescriptionContainer: {
    padding: '0 0 0 18px',
    display: 'flex',
    flexDirection: 'column',
    width: ({ maxItemWidth, itemHeight }) => maxItemWidth - itemHeight - 22,
    maxWidth: ({ maxItemWidth, itemHeight }) => maxItemWidth - itemHeight - 22,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '0 0 0 8px',
    },
  },
  playIconBtn: {
    width: 48,
    minWidth: 48,
    height: 48,
    top: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight - 4 : itemHeight) / 2 - 24,
    left: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight - 4 : itemHeight) / 2 - 24,
    zIndex: 1,
    borderRadius: 24,
    cursor: 'pointer',
    position: 'absolute',
    '@media (hover: hover)': {
      '&:hover': {
        filter: 'brightness(0.9)',
      },
    },
  },
  trackCreatorContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: ({ isSmallView }) => `calc(100% - ${isSmallView ? 10 : 16}px)`,
    height: ({ isSmallView }) => (isSmallView ? 20 : 22),
  },
  trackCreatorText: {
    fontSize: ({ isSmallView }) => (isSmallView ? 12 : 14),
    letterSpacing: 0,
    fontFamily: 'ARSMaquetteProRegular',
    color: theme.palette.colors.backdropMutedColor,
  },
  trackName: {
    fontSize: ({ isSmallView }) => (isSmallView ? 16 : 18),
    lineHeight: ({ isSmallView }) => (isSmallView ? '16px' : 'unset'),
    letterSpacing: 0,
    marginBottom: 6,
    maxWidth: '100%',
    fontFamily: 'ARSMaquetteProMedium',
    color: theme.palette.colors.backdropOnColor,
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    overflow: 'hidden',
    maxHeight: 50,
    whiteSpace: 'normal',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  trackDescription: {
    lineHeight: '15px',
    letterSpacing: 0,
    maxWidth: '100%',
    minHeight: 30,
    maxHeight: 45,
    whiteSpace: 'normal',
    fontFamily: 'ARSMaquetteProRegular',
    wordBreak: 'break-word',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    'line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    color: theme.palette.colors.backdropOnColor,
    fontSize: ({ isSmallView }) => (isSmallView ? 12 : 14),
    marginBottom: ({ isSmallView }) => (isSmallView ? 4 : 11),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginBottom: ({ isSmallView }) => (isSmallView ? 4 : 6),
    },
  },
  trackDetails: {
    fontSize: ({ isSmallView }) => (isSmallView ? 11 : 12),
    lineHeight: '24px',
    letterSpacing: 0,
    fontFamily: 'Roboto Bold',
    display: 'flex',
    alignItems: 'center',
    height: ({ isSmallView }) => (isSmallView ? 20 : 22),
    color: theme.palette.colors.backdropMutedColor,
    position: 'relative',
    marginTop: 'auto',
  },
  trackDetailsTimeIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
    color: theme.palette.colors.backdropMutedColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 14,
      height: 14,
    },
  },
  trackBookmarkIconBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.colors.backdropMutedColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: lighten(theme.palette.colors.backdropMutedColor, 0.1),
      },
    },
    padding: '0 5px',
  },
  activeBookmarkIcon: {
    width: 22,
    height: 22,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 20,
      height: 20,
    },
  },
  trackBookmarkIcon: {
    width: 22,
    height: 22,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 20,
      height: 20,
    },
  },
  trackInfoIconBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.colors.backdropMutedColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: lighten(theme.palette.colors.backdropMutedColor, 0.1),
      },
    },
    padding: '0 5px',
  },
  trackInfoIcon: {
    width: 22,
    height: 22,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 20,
      height: 20,
    },
  },
  separator: {
    width: '100%',
    maxWidth: ({ maxItemWidth }) => maxItemWidth,
    borderBottom: `0.5px solid ${theme.palette.colors.separatorColor}`,
    margin: ({ separatorHeight }) => `${separatorHeight / 2}px auto`,
  },
  buttons: {
    display: 'inline-flex',
    marginLeft: 'auto',
  }
}));

export default useStyles;
