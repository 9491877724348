import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 237,
    top: 7,
    right: '-2px',
    position: 'absolute',
    borderRadius: 13,
  },
  navButton: {
    minWidth: 106,
    height: 36,
    fontSize: 14,
    color: theme.palette.colors.secondaryOnColor,
    backgroundColor: theme.palette.colors.secondaryColor,
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: darken(theme.palette.colors.secondaryColor, 0.1),
      },
    },
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    height: 62,
    fontSize: 14,
    width: '100%',
    paddingLeft: 18,
    paddingRight: 16,
    position: 'relative',
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  menuItemText: {
    letterSpacing: 0,
    lineHeight: '24px',
    fontSize: 14,
    fontFamily: 'ARSMaquetteProRegular',
    color: theme.palette.colors.surfaceOnColor,
  },
  itemLoader: {
    top: 11,
    right: 10,
    bottom: 11,
    height: 40,
    width: 40,
    position: 'absolute',
  },
}));

export default useStyles;
